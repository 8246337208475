<template>
    <div :class="routeURL" class="personalizedbook row justify-content-center">
        <div class="col-lg-12 col-md-12">
            <div class="card bg-secondary shadow border-0">
                <div class="card-header bg-transparent">
                    <div class="row flex" v-show="!preview">
                        <div class="col-md-6 flex images-container" v-if="product.images">
                            <div class="product-thumbnail-container">
                                <div class="product-thumbnail" v-for="image, index in product.images" :key="index" @click="selectIndex(index)"
                                    :style="{ backgroundImage: 'url(' + product.images[index] + ')' }">
                                </div>
                            </div>
                            <div class="product"
                                :style="{ backgroundImage: 'url(' + product.images[selectedIndex] + ')' }">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <h1 class="pt-3 text-dark"><b>{{ product.name }}</b></h1>
                            <div class="pb-3 text-dark" v-if="product.price != 0">
                                <span :class="{'text-danger': product.priceBeforeDiscount ? true : false }">{{ parseInt(product.price).toLocaleString('en-US', {
                                                style: 'currency',
                                                currency: 'IDR',
                                                }) }}</span>
                                        <span style="padding-left: 10px; text-decoration: line-through;" v-if="product.priceBeforeDiscount">{{ parseInt(product.priceBeforeDiscount).toLocaleString('en-US', {
                                                style: 'currency',
                                                currency: 'IDR',
                                                }) }}</span>
                            </div>
                            <div class="btn btn-primary btn-checkout mt-1 mb-3" @click="processCheckout()">
                                <span>Checkout</span>
                                <i class="ni ni-bold-right"></i>
                            </div>
                            <p v-html="product.description"></p>
                            <!-- <h3>Who is this for?</h3>
                            <base-input alternative=""
                                                    label="Child Name"
                                                    placeholder="Child Name"
                                                    input-classes="form-control-alternative"
                                                    v-model="model.childname"
                                        /> -->
                            
                        </div>
                    </div>

                </div>                
            </div>
        </div>
    </div>
</template>

<script>
import firebaseApp from "@/firebase/firebaseInit";
const db = firebaseApp.firestore();
export default {
    name: 'bookabook',
    data() {
        return {
            modals: {
            modal: false
            },
            routeURL: '',
            loading: true,
            preview: false,
            product: {
                name: '',
                url: '',
                price: 0,
            },
            selectedIndex: 0,
            selections: [],
            model: {
                childname: '',
                // message: "At the end of the day you can be anything! Just follow your heart and you will find a way!",
                productName: 'Kid And The Dragon',
                productPrice: 0,
                productUrlCode: '',
                productType: 'kit',
                productImage: ''
            }
        }
    },
    mounted() {
        this.loading = true;
        this.routeURL = this.$route.params.id;
        this.selections = [
                            '/img/'+this.routeURL+'/B3.png',
                            '/img/'+this.routeURL+'/B2.png',
                            '/img/'+this.routeURL+'/B1.png',
                            '/img/'+this.routeURL+'/G3.png',
                            '/img/'+this.routeURL+'/G1.png',
                            '/img/'+this.routeURL+'/G2.png',                    
                        ];
        this.getProduct();
    },
    methods: {
        selectIndex(index){
            this.selectedIndex = index;
        },
        async getProduct() {
            const thisVue = this;
            await db.collection("products").where('url', '==', this.routeURL).get()
            .then(snapshot => {
                if (snapshot.empty) {
                    alert('No matching product, error occured.');
                    return;
                }  
                snapshot.forEach(doc => {
                    const product = doc.data();
                    // console.log(doc.id, '=>', product);
                    thisVue.product = product;
                    thisVue.model.productName = product.name;
                    thisVue.model.productPrice = parseInt(product.price);
                    thisVue.model.productPriceBeforeDiscount = parseInt(product.priceBeforeDiscount);
                    thisVue.model.productUrlCode = product.url;
                    thisVue.model.productImage = product.img;
                    // thisVue.model.selected_child = thisVue.selections[thisVue.model.selected_child_index];
                });
                this.loading = false;
            });
        },
        generateMessage(){
            const randomMessage = [
                "A book is a gift you can open again and again, just like you are a gift to the family every moment of every day.",
                "At the end of the day you can be anything, Arthur! Just follow your heart and you will find the way.",
                "May your days will always be filled with marvelous adventure that gives you great experiences, XXX!",
                "Dear XXX, remember that no matter how strong a dragon is, it can always be defeated.",
                "Dear XXX, be honest in all you say and do so others will know that you are a trustworthy person.",
                "This book is specially made for xxx",
                
                "Buku merupakan hadiah yang bisa dibuka lagi dan lagi, seperti kehadiranmu merupakan hadiah bagi keluarga ini.",
                "Pada akhirnya, kamu akan bisa menjadi apapun, XXX! Selalu ikuti kata hati mu, dan kamu akan menemukan jalan nya.",
                "Temukan dan nikmati petualangan setiap harinya yang akan menjadikan hidupmu penuh pengalaman bermakna, Erland!",
                "Semoga cerita ini akan selalu mengingatkanmu bahwa sebesar apapun masalah yang menghadang, semuanya bisa dihadapi.",
                "Jadilah orang yang jujur dan tulus, XXX… Sehingga kamu bisa menjadi orang yang dipercaya dan diandalkan.",
                "Buku ini dibuat khusus untuk xxx",
            ];

            this.model.message = randomMessage[randomMessage.length * Math.random() | 0];
        },
        checkForm(){
            if(this.model.childname == ''){
                alert('Please input your child name');
            } else if(this.model.message == ''){
                alert('Please input message your child');
            } else {
                this.modals.modal = false;
                this.preview = true;
            }
        },
        selectChild(selected_index) {
            this.model.selected_child_index = selected_index;
            this.model.selected_child = this.selections[this.model.selected_child_index];
        },
        processCheckout(){
            if(this.model.productUrlCode=='' || this.model.productPrice==0){
                alert('error occured, please contact web administrator');
            } else {
                this.cartModel.push(this.model);
                this.$store.commit("setCartState", this.cartModel);
                this.$router.push({ name: "checkout"});
            }
        }
    },
    computed: {
        cartModel() {
          return this.$store.state.cart;
        }
    },
}
</script>