<template>
  <div class="py-2 py-md-6">
    <h1 class="text-center mb-4 mb-md-6">Bookabook Blog</h1>
    <div class="row">
      <div class="col-6 col-md-8">
        <div class="row">
          <div
            v-for="(post, index) in blogs"
            :key="index"
            class="col-12 col-md-6 mb-6"
          >
            <div class="mb-3">
              <router-link :to="`/blogs/${post.code}`">
                <img
                  :src="post.image"
                  :alt="post.title"
                  width="100%"
                >
              </router-link>
            </div>
            <div>
              <router-link :to="`/blogs/${post.code}`">
                <p class="h3"><strong>{{ post.title }}</strong></p>
              </router-link>
              <p>{{ post.excerpt }}</p>
            </div>
            <div>
              <p>
                <!-- by {{ post.author }}on -->
                {{ moment(Date(post._updated_at)).format('DD MMM YYYY') }}
              </p>
            </div>
            <div>
              <router-link :to="`/blogs/${post.code}`"><base-button type="primary">Read More</base-button></router-link>
            </div>
          </div>
        </div>
      </div>
      <div class="col-6 col-md-4">
        <!-- <div class="mb-4">
          <input
            type="text"
            class="border border-gray"
          >
          <button
            class="bg-blue text-white border-0"
          >
            Search
          </button>
        </div> -->
        <div v-if="blogs.length" class="mb-4">
          <p class="h3 text-blue">
            <strong>Recent blogs</strong>
          </p>
          <p
            v-for="(post, index) in blogs"
            :key="{index}"
            class="mb-2"
          >
            <router-link :to="`/blogs/${post.code}`">
              <span class="text-black">{{ post.title }}</span>
            </router-link>
          </p>
        </div>
        <!-- <div>
          <p class="h3 text-blue">
            <strong>Get Updates in Your Inbox</strong>
          </p>
          <base-input
            placeholder="Enter your email"
            class="mb-3"
          />
          <base-button type="primary">Subscribe</base-button>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import firebaseApp from "../../firebase/firebaseInit";
const db = firebaseApp.firestore();

export default {
  data: () => ({
    blogs: [
      {
        url: 'tata-cara-masuk-perpus-jakarta-untuk-karya-raya-2023',
        title: 'Tata Cara Masuk Perpus Jakarta Untuk Karya Raya 2023',
        image: '/img/blogs/poster-karya-raya-2023-FA.jpg',
        createdAt: 'Oct 20, 2023',
        author: 'Ernest Junius',
        excerpt: 'Yuk simak tata cara masuk Perpus Jakarta Untuk Karya Raya 2023',
        commentsCount: 0
      },
      {
        url: 'giveaway-30-buku-untuk-30-hari-kebaikan-ramadan',
        title: 'Giveaway 30 Buku Untuk 30 Hari Kebaikan Ramadan!',
        image: '/img/blogs/Giveaway_110422-02_small_77118b34-c70a-40b4-a858-378d725547eb_1024x1024.webp',
        createdAt: 'April 11, 2022',
        author: 'Ernest Junius',
        excerpt: 'Tidak terasa sudah seminggu lebih memasuki bulan Ramadan! @bookabook.id ada giveaway yang berupa: 30 buku anak untuk 30 orang pemenang (3 buku personalized dan 27 buku sticker non-personalized).',
        commentsCount: 0
      },
      {
        url: 'yuk-bermain-kata-dengan-polindrom',
        title: 'Yuk bermain kata dengan palindrom',
        image: '/img/blogs/palindrom-09_1024x1024.webp',
        createdAt: 'March 19, 2022',
        author: 'Ernest Junius',
        excerpt: 'Apakah itu palindrom? Palindrom (atau palindrome dalam bahasa Inggris) adalah sebuah kata, frasa, atau kalimat yang sama persis ketika dibaca dari depan maupun dari belakang, seperti kata ini, “kodok” atau kalimat ini, “Ibu Ratna antar ubi.”',
        commentsCount: 0
      },
      {
        url: 'macan-dan-kelinci-hutan',
        title: 'Macan Dan Kelinci Hutan',
        image: '/img/blogs/IMG_1821_1024x1024.webp',
        createdAt: 'March 19, 2022',
        author: 'Ernest Junius',
        excerpt: 'A tiger found a hare sleeping near a tree, and was just going to devour her when he caught sight of a passing stag...',
        commentsCount: 0
      },
      {
        url: 'singa-beruang-dan-rubah',
        title: 'Singa Beruang Dan Rubah',
        image: '/img/blogs/FT_01-02_1024x1024.webp',
        createdAt: 'March 18, 2022',
        author: 'Ernest Junius',
        excerpt: 'A lion and a bear were fighting for a piece of meat, which they had both seized at the same moment. The battle was long and fierce, and at length both of them were exhausted, and lay upon the ground gasping for breath. A fox had all the time prowling round and watching the fight...',
        commentsCount: 0
      },
    ],
    recent: [
      { title: 'Macan dan Kelinci Hutan', url: 'macan-dan-kelinci-hutan' },
      { title: 'Singa, Beruang dan Rubah', url: 'singa-beruang-dan-rubah' },
      { title: 'Yuk bermain kata dengan palindrom', url: 'yuk-bermain-kata-dengan-polindrom' },
      { title: 'Giveaway 30 Buku Untuk 30 Hari Kebaikan Ramadan!', url: 'giveaway-30-buku-untuk-30-hari-kebaikan-ramadan' }
    ]
  }),
  mounted(){
      this.loadModel();
  },
  methods: {
    async loadModel(){
          this.blogs = [];
          const dataBase = db.collection('blogs').orderBy("_updated_at", "desc");
          const dbResults = await dataBase.get();
          console.log('dbResults', dbResults)
          dbResults.forEach((doc) => {
            const data = doc.data();
              data.id = doc.id;
              console.log(data);
              this.blogs.push(data);
          });
          this.modelLoaded = true;

        }
  }
}
</script>