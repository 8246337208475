<template>
    <div>
        <base-header type="gradient-success" class="pb-3 pt-3 pt-md-8">
        </base-header>

        <div class="container-fluid mt--5">
             <!--Tables-->
             <div class="row">
                <div class="col">
                    <div class="card shadow"
                        :class="type === 'dark' ? 'bg-default': ''">
                        <div class="card-header border-0"
                            :class="type === 'dark' ? 'bg-transparent': ''">
                            <div class="row align-items-center">
                              <div class="col">
                                  <h3 class="mb-0" :class="type === 'dark' ? 'text-white': ''">
                                      Vouchers
                                  </h3>
                              </div>
                              <div class="col text-right">
                                <base-button type="primary" size="sm" @click="addNewVoucher()">Add New Voucher</base-button>
                              </div>
                            </div>
                        </div>
                    
                        <div class="table-responsive">

                            <content-placeholders v-show="modelArray.length == 0" class="m-5">
                              <content-placeholders-heading :img="true" />
                              <content-placeholders-text :lines="2" />
                              <content-placeholders-heading :img="true" />
                              <content-placeholders-text :lines="2" />
                            </content-placeholders>

                            <base-table class="table align-items-center table-flush"
                                        :class="type === 'dark' ? 'table-dark': ''"
                                        :thead-classes="type === 'dark' ? 'thead-dark': 'thead-light'"
                                        tbody-classes="list"
                                        :data="modelArray" v-show="modelArray.length > 0">
                            <template slot="columns">
                              <th>Voucher</th>
                              <th>Status</th>
                              <th>Voucher Type</th>
                              <th>Quota</th>
                              <th>Expiry Date</th>
                              <th>Created Date</th>
                              <th></th>
                            </template>
                    
                            <template slot-scope="{row}">
                                <th scope="row">
                                  <div class="clickable" @click="edit(row.id)">
                                    {{ row.code }}
                                  </div>
                                </th>
                                <td>
                                  <div style="padding: 2px;border-radius: 5px;text-align:center;" :class="{'text-white': true,
                                      'bg-warning': !row.active,
                                      'bg-success': row.active,}
                                      ">{{ (row.active) ? 'active' : 'inactive' }}</div>
                                </td>
                                <td>
                                  {{ (row.type == 'discount_by_percentage') ? row.value*100 + '% discount'
                                      : (row.type == 'discount_by_value') ? row.value.toLocaleString('en-US', {
                                                            style: 'currency',
                                                            currency: 'IDR',
                                                          }) 
                                      : '-' }}
                                </td>
                                <td>
                                  {{ (row.quota==-1) ? 'Unlimited' : row.quota }}
                                </td>
                                <td>
                                  {{ row.expiry_date }}
                                </td>
                                <td>
                                  {{ row.created_at }}
                                </td>
                                <td class="text-right">
                                <a slot="title" @click="edit(row.id)" class="btn btn-sm btn-icon-only text-light">
                                    <i class="ni ni-bold-right"></i>
                                </a>
                                </td>
                            </template>
                    
                            </base-table>
                        </div>
                
                        <!-- <div class="card-footer d-flex justify-content-end"
                            :class="type === 'dark' ? 'bg-transparent': ''">
                            <base-pagination total=10></base-pagination>
                        </div> -->
                
                    </div>
                </div>
            </div>
            <!--End tables-->
        </div>

    </div>
</template>
<script>
  import firebaseApp from "../firebase/firebaseInit";
const db = firebaseApp.firestore();

  export default {
    name: 'tables',
    props: {
        type: {
          type: String
        },
        title: String
      },
    data() {
      return {
        modelName: 'vouchers',
        modelLoaded: false,
        modelArray: [],
      }
    },
    mounted(){
      this.loadModel();
    },
    methods: {
        async addNewVoucher(){
          if (confirm("You can use duplicate button, are you sure you want to add new voucher from blank?") == false) {
            console.log("User cancelled the prompt.");
          } else {
            await db.collection(this.modelName).add({
              code: 'NEWVOUCHER',
              active: false,
              created_at: new Date()
            }).then((result) => {
              console.log(result);
            });

            this.loadModel();
          }
        },
        edit(id) {
          console.log('edit',id);
            this.$router.push({ name: "Edit Voucher", params: { id: id } });
        },
        async loadModel(){
          this.modelArray = [];
          const dataBase = await db.collection(this.modelName).orderBy("created_at", "desc");
          const dbResults = await dataBase.get();
          dbResults.forEach((doc) => {
            // if (!this.modelArray.some((product) => product.url === doc.url)) {
              const data = doc.data();
              data.id = doc.id;
              if(data.expiry_date != null && data.expiry_date != '-') data.expiry_date = new Date(data.expiry_date.seconds*1000)
              else data.expiry_date = '-'
              if(data.created_at) data.created_at = new Date(data.created_at.seconds*1000)
              else data.created_at = '-'
              this.modelArray.push(data);
            // }
          });
          this.modelLoaded = true;

        }
    },
  };
</script>
<style>
    .avatar img{ border-radius: 0 }
    .clickable { cursor: pointer; }
    .clickable:hover { color:darkblue}
    .clickable:hover img { box-shadow: 1px 1px 1px darkblue;}
</style>
