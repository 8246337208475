<template>
    <div>
        <base-header type="gradient-success" class="pb-3 pt-3 pt-md-8">
        </base-header>

        <div class="container-fluid mt--5">
             <!--Tables-->
             <div class="row">
                <div class="col">
                    <div class="card shadow"
                        :class="type === 'dark' ? 'bg-default': ''">
                        <div class="card-header border-0"
                            :class="type === 'dark' ? 'bg-transparent': ''">
                            <div class="row align-items-center">
                              <div class="col">
                                  <h3 class="mb-0" :class="type === 'dark' ? 'text-white': ''">
                                      Byme Uploads
                                  </h3>
                              </div>
                              <!-- <div class="col text-right">
                                <base-button type="primary" size="sm" @click="addNewVoucher()">Add New Voucher</base-button>
                              </div> -->
                            </div>
                        </div>
                    
                        <div class="table-responsive">

                            <content-placeholders v-show="modelArray.length == 0" class="m-5">
                              <content-placeholders-heading :img="true" />
                              <content-placeholders-text :lines="2" />
                              <content-placeholders-heading :img="true" />
                              <content-placeholders-text :lines="2" />
                            </content-placeholders>

                            <base-table class="table align-items-center table-flush"
                                        :class="type === 'dark' ? 'table-dark': ''"
                                        :thead-classes="type === 'dark' ? 'thead-dark': 'thead-light'"
                                        tbody-classes="list"
                                        :data="modelArray" v-show="modelArray.length > 0">
                            <template slot="columns">
                                <th>Byme Uploads</th>
                                <th></th>
                            </template>
                    
                            <template slot-scope="{row}">
                                <th scope="row">
                                    <div class="media align-items-center clickable">
                                        <a class="avatar mr-3" @click="edit(row.id)" v-show="row.cover">
                                            <img alt="Image placeholder" :src="row.cover">
                                        </a>
                                        <a @click="edit(row.id)" class="text-dark">
                                        <span class="name mb-0 text-sm">{{row._id}}</span>
                                    </a>
                                    </div>
                                </th>
                                <td class="text-right">
                                <a slot="title" @click="edit(row.id)" class="btn btn-sm btn-icon-only text-light">
                                    <i class="ni ni-bold-right"></i>
                                </a>
                                </td>
                            </template>
                    
                            </base-table>
                        </div>
                
                        <!-- <div class="card-footer d-flex justify-content-end"
                            :class="type === 'dark' ? 'bg-transparent': ''">
                            <base-pagination total=10></base-pagination>
                        </div> -->
                
                    </div>
                </div>
            </div>
            <!--End tables-->
        </div>

    </div>
</template>
<script>
  import firebaseApp from "../firebase/firebaseInit";
const db = firebaseApp.firestore();

  export default {
    name: 'tables',
    props: {
        type: {
          type: String
        },
        title: String
      },
    data() {
      return {
        modelName: 'bymes',
        modelLoaded: false,
        modelArray: [],
      }
    },
    mounted(){
      this.loadModel();
    },
    methods: {
        async addNewVoucher(){
          if (confirm("You can use duplicate button, are you sure you want to add new voucher from blank?") == false) {
            console.log("User cancelled the prompt.");
          } else {
            await db.collection(this.modelName).add({
              code: 'NEWVOUCHER',
              active: false,
              created_at: new Date()
            }).then((result) => {
              console.log(result);
            });

            this.loadModel();
          }
        },
        edit(id) {
          console.log('edit',id);
            this.$router.push({ name: "View Byme Upload", params: { id: id } });
        },
        async loadModel(){
          this.modelArray = [];
          const dataBase = await db.collection(this.modelName);
          const dbResults = await dataBase.get();
          dbResults.forEach((doc) => {
            // if (!this.modelArray.some((product) => product.url === doc.url)) {
              const data = doc.data();
              data.id = doc.id;
              this.modelArray.push(data);
            // }
          });
          this.modelLoaded = true;

        }
    },
  };
</script>
<style>
    .avatar img{ border-radius: 0 }
    .clickable { cursor: pointer; }
    .clickable:hover { color:darkblue}
    .clickable:hover img { box-shadow: 1px 1px 1px darkblue;}
</style>
