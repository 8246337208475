var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-lg-12 col-md-12"},[_c('div',[_c('div',{staticClass:"bg-transparent text-center"},[_c('content-placeholders',{directives:[{name:"show",rawName:"v-show",value:(_vm.products.length == 0),expression:"products.length == 0"}],staticClass:"m-5"},[_c('content-placeholders-heading',{attrs:{"img":true}}),_c('content-placeholders-text',{attrs:{"lines":2}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.products.length > 0),expression:"products.length > 0"}]},[_c('h1',{staticClass:"my-5"},[_vm._v("Browse Our Amazing Books")]),_c('div',{ref:"products",staticClass:"row products"},_vm._l((_vm.products),function(product,index){return _c('div',{key:index,staticClass:"col-md-4 col-sm-6 px-2 py-2",on:{"click":_vm.scrollToTop}},[_c('router-link',{attrs:{"to":(product.url == 'kid-and-the-dragon'
                                                    || product.url == 'where-are-you' 
                                                    || product.url == 'a-very-dinosaur-birthday' 
                                                    || product.url == 'a-very-unicorn-birthday' 
                                                    || product.url == 'an-intergalactic-birthday-party' 
                                                    || product.url == 'bali' 
                                                    || product.url == 'jakarta' 
                                                    || product.url == 'good-night' 
                                                    || product.url == 'one-day-you-will-fly' 
                                                    || product.url == 'happy-birthday' 
                                                    || product.url == 'promoted-to-big-brother-sister' 
                                                    || product.url == 'ramadan-seru' 
                                                    || product.url == 'yogyakarta' 
                                                    || product.url == 'welcome-to-the-world' 
                                                    || product.url == 'where-are-you-birthday' )
                                                    ? '/'+product.url
                                                    : 
                                                        (product.type == 'Personalized Book')
                                                        ? '/custombook/'+product.url
                                                        : '/product/'+product.url}},[_c('div',{staticClass:"book",style:({ backgroundImage: 'url(' + product.img + ')' })}),_c('div',{staticClass:"pt-3 text-dark name"},[_vm._v(_vm._s(product.name))]),_c('div',{staticClass:"pb-5 text-dark price"},[_c('b',[_c('div',{class:{'text-danger': product.priceBeforeDiscount ? true : false },staticStyle:{"display":"inline-block"}},[_vm._v(_vm._s(parseInt(product.price).toLocaleString('en-US', { style: 'currency', currency: 'IDR', })))]),_vm._v(" "),(product.priceBeforeDiscount)?_c('div',{staticStyle:{"display":"inline-block","text-decoration":"line-through"}},[_vm._v(_vm._s(parseInt(product.priceBeforeDiscount).toLocaleString('en-US', { style: 'currency', currency: 'IDR', })))]):_vm._e()])])])],1)}),0)])],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }