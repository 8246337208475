<template>
  <div class="byme-upload-view">
    <base-header type="gradient-success" class="pb-3 pt-1 pt-md-8">
    <!-- <h1 style="color: white" v-if="!loading">{{ title }}</h1> -->
    </base-header>

    <div class="mt--5 col-xl-12 order-xl-1">
      <card shadow style="padding:0">
        <content-placeholders v-show="loading" class="m-5">
            <content-placeholders-heading :img="true" />
            <content-placeholders-text :lines="2" />
            <content-placeholders-heading :img="true" />
            <content-placeholders-text :lines="2" />
          </content-placeholders>
          <div slot="header" class="bg-white border-0" v-show="!loading">
              <div class="row align-items-center">
                  <div class="col-4">
                      <h3 class="mb-0">{{ title }}</h3>
                  </div>
                  <!-- <div class="col-8 text-right item-menu">
                    <div @click="duplicateModel()" class="btn btn-sm btn-primary">Duplicate</div>
                    <div @click="deleteModel()" class="btn btn-sm btn-danger">Delete</div>
                  </div> -->
              </div>
          </div>
          <template>
              <form @submit.prevent v-show="!loading">
                  <div class="container">
                    <div :class="{ invisible: !error }" class="err-message">
                      <p><span>Error:</span>{{ this.errorMsg }}</p>
                    </div>
                    <div class="row">
                      <div class="col-lg-12">
                        <div><b>ID: {{ model._id }}</b></div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-6">
                        <div class="form-group">
                          <div>Cover:</div>
                          <div>
                            <img class="byme-upload-view" :src="model.cover">
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="form-group">
                          <div>Back Cover:</div>
                          <div>
                            <img class="byme-upload-view" :src="model['back-cover']">
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6" v-for="index in 10" :key="index">
                        <div class="form-group">
                          <div>
                            <img class="byme-upload-view" :src="model['page-'+index]">
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- <div class="blog-actions">
                      <button class="btn btn-sm btn-primary" @click="updateModel">Save Changes</button>
                    </div> -->
                  </div>        
              </form>
          </template>
      </card>
    </div>
  </div>
</template>

<script>
// import { Timestamp } from "firebase/firestore";
import "firebase/storage";
import firebaseApp from "../firebase/firebaseInit";
const db = firebaseApp.firestore();

export default {
  name: "CreatePost",
  data() {
    return {
      id: null,
      file: null,
      error: null,
      errorMsg: null,
      loading: null,
      type: '',
      totalCommission: 0,
      voucherTypes: [
        'Discount By Percentage',
        'Discount By Value',
        'Free Lowest Price Product',
        'Free Shipping Only'],
      model: {
        _id: '',
        img: null,
        free_shipping: false,
      },
      orders: [],
      title: "View Byme",
      editorSettings: {
        modules: {
          imageResize: {},
        },
      },
    };
  },
  async mounted() {
    this.loading = true;
    this.routeID = this.$route.params.id;
    this.getModel();
  },
  methods: {
    setActive(status){
      this.model.active = status;
    },
    setFreeShipping(status){
      this.model.free_shipping = status;
    },
    setType(type){
      this.model.type = type;
      if(type=='free_shipping_only') this.model.free_shipping = true;
    },

    async deleteModel() {
      if (confirm("This action can't be undone, if you want to remove the book from customer display, you can update the status to archived instead. Are you sure you want to delete this product?") == false) {
        console.log("User cancelled the prompt.");
      } else {
        this.loading = true;
        await db.collection("vouchers").doc(this.routeID).delete().then(
          () => {
            alert('your product has sucessfully been deleted, you will be redirected to vouchers list page');
            this.$router.push({ name: "vouchers"});
          }
        );
      }
    },

    async getModel() {
      const snapshot = await db.collection("bymes").doc(this.routeID).get();
      console.log(snapshot.data());
      this.model = snapshot.data();
      this.title = "Byme ID: "+this.model._id;
      this.loading = false;
      
    },

  },
  computed: {
    profileId() {
      return this.$store.state.profileId;
    },
    productPhotoName() {
      return this.$store.state.productPhotoName;
    },
    productDescription: {
      get() {
        return this.$store.state.productDescription;
      },
      set(payload) {
        this.$store.commit("newProduct", payload);
      },
    },
  },
};
</script>

<style lang="scss">
img.byme-upload-view {
  max-width: 100%;
}
</style>
