<template>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-12 col-md-12">
                <div>
                    <div class="bg-transparent text-center">
                        <content-placeholders v-show="products.length == 0" class="m-5">
                            <content-placeholders-heading :img="true" />
                            <content-placeholders-text :lines="2" />
                        </content-placeholders>
                        <div v-show="products.length > 0" >
                            <h1 class="my-5">KREARTions by Kids</h1>
                            <div class="row products" ref="products">
                                <div class="col-md-4 col-sm-6 px-2 py-2" v-for="product, index in products" :key="index" @click="scrollToTop" >
                                    <router-link :to="'/product/'+product.url">
                                    <!-- <router-link :to="product.url"> -->
                                        <div class="book"
                                            :style="{ backgroundImage: 'url(' + product.img + ')' }">
                                        </div>
                                        <div class="pt-3 text-dark name">{{ product.name }}</div>
                                        <div class="designer" v-if="product.designer">Design by {{ product.designer }}</div>
                                        <div class="pb-5 text-dark price">
                                            <b>
                                                <div :class="{'text-danger': product.priceBeforeDiscount ? true : false }" style="display:inline-block;">{{ parseInt(product.price).toLocaleString('en-US', {
                                                        style: 'currency',
                                                        currency: 'IDR',
                                                        }) }}</div>
                                                        &nbsp;
                                                <div style="display:inline-block; text-decoration: line-through;" v-if="product.priceBeforeDiscount">{{ parseInt(product.priceBeforeDiscount).toLocaleString('en-US', {
                                                        style: 'currency',
                                                        currency: 'IDR',
                                                        }) }}</div>
                                            </b>
                                        </div>  
                                        
                                        
                                                    
                                    </router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'bookabook',
    data() {
        return {
            query: '',
            model: {
            }
        }
    },
    // mounted(){
    //     console.log(this.$route.query);
    // },
    computed: {
        products() {
            if(this.$route.query.age)
                return this.$store.state.products.filter(obj => {
                    return obj.ageCategory === this.$route.query.age;
                    }).filter(obj => {
                    return obj.status === 'active';
                    });
            else if(this.$route.query.language)
                return this.$store.state.products.filter(obj => {
                    return obj.languageCategory === this.$route.query.language;
                    }).filter(obj => {
                    return obj.status === 'active';
                    });            
            else if(this.$route.query.category)
                return this.$store.state.products.filter(obj => {
                    return obj.category ? obj.category.includes(this.$route.query.category) : false;
                    }).filter(obj => {
                    return obj.status === 'active';
                    });            
            else
                return this.$store.state.products.filter(obj => {
                    return obj.category ? obj.category.includes("Kreart") : false;
                    }).filter(obj => {
                    return obj.status === 'active';
                    });
        }
    },
    methods: {
    scrollToProducts() {
      this.$refs["products"].scrollIntoView({ behavior: "smooth" })
    },
    scrollToTop() {
        document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
    }
  },
}

</script>
<style>
    .clickable { cursor: pointer; }

    .hero-img { max-width: 100%; }

</style>
