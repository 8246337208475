<template>
    <div>
        <base-header type="gradient-success" class="pb-3 pt-3 pt-md-8">
        </base-header>

        <div class="container-fluid mt--5">
             <!--Tables-->
             <div class="row">
                <div class="col">
                    <div class="card shadow"
                        :class="type === 'dark' ? 'bg-default': ''">
                        <div class="card-header border-0"
                            :class="type === 'dark' ? 'bg-transparent': ''">
                            <div class="row align-items-center">
                              <div class="col">
                                  <h3 class="mb-0" :class="type === 'dark' ? 'text-white': ''">
                                      Products
                                  </h3>
                              </div>
                              <div class="col text-right">
                                <base-button type="primary" size="sm" @click="addNewProduct()">Add New Product</base-button>
                              </div>
                            </div>
                        </div>
                    
                        <div class="table-responsive">

                            <content-placeholders v-show="modelArray.length == 0" class="m-5">
                              <content-placeholders-heading :img="true" />
                              <content-placeholders-text :lines="2" />
                              <content-placeholders-heading :img="true" />
                              <content-placeholders-text :lines="2" />
                            </content-placeholders>

                            <base-table class="table align-items-center table-flush"
                                        :class="type === 'dark' ? 'table-dark': ''"
                                        :thead-classes="type === 'dark' ? 'thead-dark': 'thead-light'"
                                        tbody-classes="list"
                                        :data="modelArray" v-show="modelArray.length > 0">
                            <template slot="columns">
                              <th>Product</th>
                                <th>Status</th>
                                <th>Pricing</th>
                                <th>Age</th>
                                <th>Language</th>
                                <th>Category</th>
                                <th>Priority</th>
                                <th>Updated At</th>
                                <th></th>
                            </template>
                    
                            <template slot-scope="{row}">
                                <th scope="row">
                                <div class="media align-items-center clickable">
                                    <a class="avatar mr-3" :href="'edit-product/'+row.id">
                                        <img alt="Image placeholder" :src="row.img">
                                    </a>
                                    <a :href="'edit-product/'+row.id" class="text-dark">
                                      <span class="name mb-0 text-sm">{{row.name}}</span>
                                    </a>
                                </div>
                                </th>
                                <td>
                                <badge class="badge-dot mr-4" :type="row.statusType">
                                    <i :class="`bg-${row.statusType}`"></i>
                                    <span class="status">{{row.status}}</span>
                                </badge>
                                </td>
                                <td class="price">
                                {{row.price}}
                                </td>
                                <td>
                                {{ row.ageCategory }}
                                </td>
                                <td>
                                {{ row.languageCategory }}
                                </td>
                                <td>
                                {{ row.category }}
                                </td>
                                <td>
                                  {{ row.priority }}
                                </td>
                                <td>
                                  {{ (row.updated_at) ? row.updated_at.toDate() : '' }}
                                </td>
                                <td class="text-right">
                                <a slot="title" @click="edit(row.id)" class="btn btn-sm btn-icon-only text-light">
                                    <i class="ni ni-bold-right"></i>
                                </a>
                                </td>
                    
                            </template>
                    
                            </base-table>
                        </div>
                
                        <!-- <div class="card-footer d-flex justify-content-end"
                            :class="type === 'dark' ? 'bg-transparent': ''">
                            <base-pagination total=10></base-pagination>
                        </div> -->
                
                    </div>




                </div>
            </div>
            <!--End tables-->
        </div>

    </div>
</template>
<script>
  import firebaseApp from "../firebase/firebaseInit";
const db = firebaseApp.firestore();

  export default {
    name: 'tables',
    props: {
        type: {
          type: String
        },
        title: String
      },
    data() {
      return {
        modelName: 'products',
        modelLoaded: false,
        modelArray: [],
      }
    },
    mounted(){
      this.loadModel();
    },
    methods: {
        async addNewProduct(){
          if (confirm("You can use duplicate button, are you sure you want to add new product from blank?") == false) {
            console.log("User cancelled the prompt.");
          } else {
            await db.collection(this.modelName).add({
              name: 'New Product',
              price: 0,
              status: 'inactive',
              date: new Date()
            }).then((result) => {
              console.log(result);
            });

            this.loadModel();
          }
        },
        edit(id) {
            this.$router.push({ name: "Edit Product", params: { id: id } });
        },
        async loadModel(){
          this.modelArray = [];
          const dataBase = await db.collection(this.modelName).orderBy("updated_at", "desc");
          const dbResults = await dataBase.get();
          dbResults.forEach((doc) => {
            // if (!this.modelArray.some((product) => product.url === doc.url)) {
              const data = doc.data();
              data.id = doc.id;
              this.modelArray.push(data);
            // updater
            // db.collection(this.modelName).doc(doc.id).update({'images': [data.img]});
            // }
          });
          this.modelLoaded = true;

        }
    },
  };
</script>
<style>
    .avatar img{ border-radius: 0 }
    .clickable { cursor: pointer; }
    .clickable:hover { color:darkblue}
    .clickable:hover img { box-shadow: 1px 1px 1px darkblue;}
</style>
