<template>
    <div class="row justify-content-center">
        <div class="col-lg-5 col-md-7">
            <div class="card bg-secondary shadow border-0">
                <div class="card-body px-lg-5 py-lg-5">
                    <div class="text-center text-muted mb-4">
                        <small>Sign up with credentials</small>
                    </div>
                    <form role="form">

                        <base-input class="input-group-alternative mb-3"
                                    placeholder="First Name"
                                    addon-left-icon="ni ni-hat-3"
                                    v-model="firstName">
                        </base-input>
                        <base-input class="input-group-alternative mb-3"
                                    placeholder="Last Name"
                                    addon-left-icon="ni ni-hat-3"
                                    v-model="lastName">
                        </base-input>

                        <base-input class="input-group-alternative mb-3"
                                    placeholder="Email"
                                    addon-left-icon="ni ni-email-83"
                                    v-model="email">
                        </base-input>

                        <base-input class="input-group-alternative"
                                    placeholder="Password"
                                    type="password"
                                    addon-left-icon="ni ni-lock-circle-open"
                                    v-model="password">
                        </base-input>

                        <!-- <div class="text-muted font-italic">
                            <small>password strength: <span class="text-success font-weight-700">strong</span></small>
                        </div> -->

                        <div class="row my-4">
                            <div class="col-12">
                                <base-checkbox class="custom-control-alternative">
                                    <span class="text-muted">I agree with the <a href="#!">Privacy Policy</a></span>
                                </base-checkbox>
                            </div>
                        </div>
                        <div class="text-center">
                            <base-button type="primary" class="my-4" @click.prevent="register">Create account</base-button>
                        </div>

                        <div v-show="error" class="error">{{ this.errorMsg }}</div>
                        
                    </form>
                </div>
            </div>
            <div class="row mt-3 mb-5">
                <div class="col-6">
                    <router-link to="/forgot-password" class="text-dark"><small>Forgot password?</small></router-link>
                </div>
                <div class="col-6 text-right">
                    <router-link to="/login" class="text-dark">
                        <small>Login into your account</small>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import firebase from "firebase/app";
import "firebase/auth";
import firebaseApp from "../firebase/firebaseInit";
const db = firebaseApp.firestore();
export default {
  name: "Register",
  data() {
    return {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      error: null,
      errorMsg: "",
    };
  },
  methods: {
    async register() {
      if (
        this.email !== "" &&
        this.password !== "" &&
        this.firstName !== "" &&
        this.lastName !== ""
      ) {
        this.error = false;
        this.errorMsg = "";
        const firebaseAuth = await firebase.auth();
        const createUser = await firebaseAuth.createUserWithEmailAndPassword(this.email, this.password);
        const result = await createUser;
        const dataBase = db.collection("users").doc(result.user.uid);
        await dataBase.set({
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
        });
        this.$router.push({ name: "dashboard" });
        return;
      }
      this.error = true;
      this.errorMsg = "Please fill out all the fields!";
      return;
    },
  },
};
</script>
<style>
    .error {
        color: red;
        text-align: center;
        font-size: small
    }
</style>
